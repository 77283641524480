<template>
  <v-navigation-drawer
    class="map-bar"
    app
  >
    <v-list-item class="px-0">
      <v-list-item-content class="py-0" style="height: 48px;">
        <UserAvatar v-if="userName">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="bg-ease"
              v-bind="attrs"
              dark
              text
              tile
              x-large
              height="100%"
              v-on="on"
            >
              <v-icon left>
                mdi-account-circle
              </v-icon>
              {{ userName }}
            </v-btn>
          </template>
        </UserAvatar>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <TranslationMenu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" text tile large height="100%" v-on="on">
              <v-icon> mdi-translate </v-icon>
            </v-btn>
          </template>
        </TranslationMenu>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-btn
          v-if="isMapModel"
          text
          tile
          x-large
          height="100%"
          :to="{
            name: 'MapViewer',
            params: $route.params,
            query: $route.query
          }"
        >
          <v-icon left>
            mdi-eye
          </v-icon>
          {{ $t('viewer_model') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-spacer />
      </v-list-item-content>
    </v-list-item>

    <!-- <v-img
        contain
        max-height="100%"
        src="@/assets/images/logo.png"
        style="cursor: pointer"
        @click="toHomePage"
      /> -->

    <v-list-item>
      <v-list-item-content>
        <FileManagerDialog v-on="$listeners">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              tile
              x-large
              height="100%"
              exact
              v-on="on"
            >
              <v-icon left>
                mdi-folder-outline
              </v-icon>
              {{ $t('file_manager') }}
            </v-btn>
          </template>
        </FileManagerDialog>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <SimulationDialog
          v-on="$listeners"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              text
              tile
              x-large
              height="100%"
              exact
              style="width: 100%;"
              v-on="on"
            >
              <v-icon left>
                mdi-chevron-triple-right
              </v-icon>
              {{ $t('simulation') }}
            </v-btn>
          </template>
        </SimulationDialog>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-btn
          text
          tile
          x-large
          height="100%"
          exact
          :disabled="!layerNodes.length"
          :to="{
            name: 'MapPostprocess',
            params: $route.params
          }"
          @click="initPostprocess()"
        >
          <v-icon left>
            mdi-checkerboard-plus
          </v-icon>
          {{ $t('post_processing_tools') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-btn
          text
          tile
          x-large
          height="100%"
          :to="{
            name:'MapDrawer',
            params: $route.params,
            query: $route.query
          }"
        >
          <v-icon left>
            mdi-pencil-ruler
          </v-icon>
          {{ $t('hand_drawn_tools') }}
        </v-btn>
      </v-list-item-content>
    </v-list-item>

    <!-- <AnimationDialog>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          text
          tile
          x-large
          height="100%"
          v-on="on"
        >
          <v-icon left>
            mdi-play-box-multiple
          </v-icon>
          製作動畫
        </v-btn>
      </template>
    </AnimationDialog> -->
    <v-spacer />

    <v-toolbar-title
      class="mx-11 d-flex align-center"
      style=" min-width: 40px;"
    >
      <v-btn color="primary" style="width: 100%;" rounded @click="toHomePage">
        <v-icon left>
          mdi-home-variant-outline
        </v-icon>
        {{ $t('back_to_home') }}
      </v-btn>
    </v-toolbar-title>
  </v-navigation-drawer>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TranslationMenu from '@/components/TranslationMenu'
import FileManagerDialog from '@/components/FileManager/FileManagerDialog'
import SimulationDialog from '@/components/Simulation/SimulationDialog'
// import AnimationDialog from '@/components/Map/Animation/AnimationDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'MapBar',

  components: {
    UserAvatar,
    TranslationMenu,
    FileManagerDialog,
    SimulationDialog
    // AnimationDialog
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName',
      layerNodes: 'map/layerNodes',
      isMapModelByRoute: 'map/isMapModelByRoute'
    }),

    isMapModel() {
      return this.isMapModelByRoute(this.$route)
    }
  },

  created() {
    if (!this.userName) {
      this.fetchUser()
    }
  },

  methods: {
    fetchUser() {
      this.$store.dispatch('user/fetchUser')
    },
    toHomePage() {
      this.$router.push({ name: 'Projects' }).catch(() => {})
    },
    initPostprocess() {
      this.$store.dispatch('postprocess/init')
    }
  }
}
</script>

<style lang="scss" scoped>
.map-bar {
  ::v-deep .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;

    .v-list-item {
      flex: 0 0;
      padding: 0;

      .v-list-item__content {
        padding: 0;
        height: 48px;
      }
    }
  }
}
</style>
