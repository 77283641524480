<template>
  <v-list
    min-height="200"
    min-width="350"
    max-height="400"
    dense
    style="overflow-y: auto;"
  >
    <v-list-item-group
      v-model="activeFileNode"
      color="primary"
    >
      <v-list-item
        v-for="(childrenNode, iChildrenNode) in childrenNodes"
        v-show="iChildrenNode"
        :key="`${childrenNode.uuid}-${iChildrenNode}`"
        class="px-5"
        :value="childrenNode"
        :ripple="false"
        @dblclick.native="selectFileNode(childrenNode)"
      >
        <v-list-item-icon
          class="mr-5 align-center"
        >
          <v-btn
            v-if="childrenNode.type !== selectFileType"
            icon
            color="primary"
            @click="selectFileNode(childrenNode)"
          >
            <v-icon>
              $vuetify.icons.enter-folder
            </v-icon>
          </v-btn>
          <v-icon
            v-else-if="childrenNode.type !== FILE_TYPE.FILE"
            color="primary"
          >
            $vuetify.icons.folder
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            {{
              childrenNode.maplayerType === 'SETTINGS_FILE'
                ? '$vuetify.icons.settings-file'
                : $vuetify.icons.values[childrenNode.fileType]
                  ? `$vuetify.icons.${childrenNode.fileType}`
                  : '$vuetify.icons.unknown'
            }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          <v-row
            no-gutters
            align="center"
            :title="childrenNode.name"
          >
            <v-col
              class="text-truncate"
            >
              {{ childrenNode.name }}
            </v-col>
          </v-row>
        </v-list-item-title>

        <v-list-item-action>
          <v-progress-circular
            v-if="
              childrenNode.isLoading===true
            "
            class="mr-2"
            color="grey"
            width="2"
            size="20"
            indeterminate
          />
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'FileList',

  props: {
    selectFileType: {
      type: String,
      default: FILE_TYPE.FILE,
      validator: value => {
        return [FILE_TYPE.FOLDER, FILE_TYPE.MAPSET, FILE_TYPE.FILE].includes(value)
      }
    },
    parentMaplayerUuid: {
      type: String,
      default: null
    },
    filterParentMaplayer: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      fileTree: state => state.globalFiles.fileTree,
      storeActiveFileNode: state => state.globalFiles.activeFileNode,
      selectedFileNode: state => state.globalFiles.selectedFileNode
    }),
    ...mapGetters({
      folderNodes: 'globalFiles/folderNodes',
      hasSelectedFileNode: 'globalFiles/hasSelectedFileNode'
    }),

    activeFileNode: {
      get() {
        return this.storeActiveFileNode
      },
      set(newVal) {
        this.$store.dispatch('globalFiles/activeFileNode', {
          fileNode: newVal,
          open: !!newVal
        })
      }
    },
    root() {
      return this.fileTree?.root
    },
    childrenNodes() {
      if (!this.hasSelectedFileNode) {
        return [this.root, ...this.folderNodes]
      }

      let children = this.selectedFileNode.type === FILE_TYPE.FILE
        ? this.selectedFileNode.parent?.children || []
        : this.selectedFileNode.children

      if (
        this.filterParentMaplayer &&
        this.selectedFileNode.type === FILE_TYPE.MAPSET
      ) {
        children = children.filter(fileNode => {
          if (!this.parentMaplayerUuid) {
            return !fileNode.parentMaplayerUuid
          }

          return fileNode.parentMaplayerUuid === this.parentMaplayerUuid
        })
      }

      // FIXME: v-list-item-group的v-model沒對應到v-list-item時
      // 會$emit('input', undefined)使active失效
      // 所以給一個不顯示的selectedFileNode在第一個, 避開此issue
      return [this.selectedFileNode, ...children]
    }
  },

  methods: {
    selectFileNode(fileNode) {
      if (!fileNode) {
        return
      }
      this.$emit('selectFileNode', fileNode)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
