<template>
  <v-menu
    v-model="menu"
    top
    offset-x
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn
          v-bind="attrs"
          color="primary"
          :disabled="disabled"
          v-on="on"
        >
          {{ $t('select') }}
        </v-btn>
      </slot>
    </template>

    <v-card
      max-width="350"
    >
      <v-card-title
        class="
          pa-2
          grey lighten-4 font-weight-bold
          grey--text text--darken-1
        "
      >
        <v-row
          class="flex-nowrap"
          align="center"
          no-gutters
          style="width: 100%;"
        >
          <v-col cols="auto">
            <v-btn
              v-if="step > 1"
              :disabled="step === 1"
              icon
              @click="previous"
            >
              <v-icon>
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            class="text-truncate menu-draggable"
            :class="{
              'pl-2': step === 1
            }"
            :title="currentTitle"
            style="cursor: pointer; user-select: none;"
          >
            {{ currentTitle }}
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon small
              @click="cancel"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-progress-linear
        v-if="isLoading"
        indeterminate
        absolute
      />

      <v-window v-model="step">
        <v-window-item :value="1">
          <FileList
            :select-file-type="selectFileType"
            :filter-parent-maplayer="filterParentMaplayer"
            @selectFileNode="selectFileNode"
          />
        </v-window-item>

        <v-window-item :value="2">
          <FileList
            :select-file-type="selectFileType"
            :filter-parent-maplayer="filterParentMaplayer"
            @selectFileNode="selectFileNode"
          />
        </v-window-item>

        <v-window-item :value="3">
          <FileList
            :select-file-type="selectFileType"
            :parent-maplayer-uuid="parentMaplayerUuid"
            :filter-parent-maplayer="filterParentMaplayer"
            @selectFileNode="selectFileNode"
          />
        </v-window-item>
      </v-window>

      <v-divider />

      <v-card-actions>
        <AddFolderDialog
          v-if="
            !selectedFileNode ||
              selectedFileNode.type !== FILE_TYPE.MAPSET
          "
          :parent="selectedFileNode"
          store-module="globalFiles"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              color="primary"
              :title="$t('page.files.ceate_folder')"
              v-on="on"
            >
              <v-icon>
                mdi-folder-plus
              </v-icon>
            </v-btn>
          </template>
        </AddFolderDialog>
        <v-spacer />
        <v-btn
          color="primary"
          depressed
          :disabled="
            !activeFileNode
          "
          @click="
            step < totalStep
              ?selectFileNode(activeFileNode)
              :submit(activeFileNode)
          "
        >
          {{
            step >= totalStep
              ? $t('confirm')
              : $t('enter')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import AddFolderDialog from '@/components/FileManager/Functions/ActionDialogs/AddFolderDialog'
import FileList from './FileList'

import { FileNode } from '@/models'
import { FILE_TYPE } from '@/models/utils'

import { mapGetters, mapState } from 'vuex'

// 順序很重要
const allowFileTypes = [FILE_TYPE.FOLDER, FILE_TYPE.MAPSET, FILE_TYPE.FILE]

export default {
  name: 'FileSelect',

  components: {
    FileList,
    AddFolderDialog
  },

  props: {
    value: {
      type: [FileNode, Object, Array, String, Number, Boolean],
      default: null
    },
    selectFileType: {
      type: String,
      default: FILE_TYPE.FILE,
      validator: value => {
        return allowFileTypes.includes(value)
      }
    },
    simulationModelUuid: {
      type: String,
      default: null
    },
    parentMaplayerUuid: {
      type: String,
      default: null
    },
    filterParentMaplayer: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    returnProperty: {
      // return FileNode property
      type: String,
      default: null
    }
  },

  data: () => ({
    menu: false,
    step: 1,
    FILE_TYPE
  }),

  computed: {
    ...mapState({
      fileTree: state => state.globalFiles.fileTree,
      storeActiveFileNode: state => state.globalFiles.activeFileNode,
      selectedFileNode: state => state.globalFiles.selectedFileNode
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    activeFileNode: {
      get() {
        return this.storeActiveFileNode
      },
      set(newVal) {
        this.$store.dispatch('globalFiles/activeFileNode', {
          fileNode: newVal,
          open: !!newVal
        })
      }
    },
    isLoading() {
      if (!this.selectedFileNode) {
        return this.root.isLoading
      }

      return this.selectedFileNode.isLoading
    },
    currentTitle() {
      switch (this.step) {
        case 1: return this.$t('project_folders')
        default: return this.selectedFileNode?.name
      }
    },
    project() {
      return this.getProjectByRoute(this.$route)
    },
    root() {
      return this.fileTree?.root
    },
    totalStep() {
      return allowFileTypes.indexOf(this.selectFileType) + 1
    }
  },

  watch: {
    menu: {
      handler(newVal) {
        // if (newVal && this.allowFileNode) {
        //   this.step = 3
        //   this.fetchChildren()
        //     .then(() => {
        //       return this.$store.dispatch('globalFiles/fetchFileSubTree', {
        //         project: this.project,
        //         fileUuid: this.allowFileNode.uuid
        //       })
        //     })

        //   return
        // }

        newVal
          ? this.allowFileNode
            ? this.selectFileNode(this.allowFileNode)
            : this.fetchChildren()
          : this.init()
      },
      immediate: true
    }
  },

  methods: {
    init() {
      this.step = 1

      this.$store.dispatch('globalFiles/init')
    },
    close() {
      this.menu = false
    },
    cancel() {
      this.close()
    },
    previous() {
      this.activeFileNode = this.selectedFileNode
      this.$store.dispatch('globalFiles/selectFileNode', {
        fileNode: this.selectedFileNode.parent
      })

      this.step--
    },
    next() {
      this.step++
    },
    submit(fileNode) {
      if (
        !fileNode ||
        fileNode.type !== this.selectFileType
      ) {
        return
      }

      const returnValue = this.returnProperty
        ? fileNode[this.returnProperty]
        : fileNode

      this.$emit('input', returnValue)
      this.$emit('change', returnValue)

      this.cancel()
    },
    async selectFileNode(fileNode) {
      if (fileNode.type === this.selectFileType) {
        if (fileNode.type !== FILE_TYPE.FILE) {
          // for check mapset files
          this.activeFileNode = fileNode
          await this.fetchChildren(fileNode)
        }
        this.submit(fileNode)
        return
      }

      this.activeFileNode = null
      this.$store.dispatch('globalFiles/selectFileNode', {
        fileNode
      })

      this.fetchChildren(fileNode)

      this.next()
    },
    fetchChildren(parentNode) {
      // 不指定parentNode就是fetch資料夾
      const parent = parentNode || this.root
      const project = this.project
      return this.$store.dispatch('globalFiles/fetchFileNodes', {
        project,
        parent,
        simulationModelUuid: parentNode?.type === FILE_TYPE.MAPSET ? this.simulationModelUuid : null,
        parentMaplayerUuid: parentNode?.type === FILE_TYPE.MAPSET ? this.parentMaplayerUuid : null
      })
        .catch(error => this.$store.dispatch('snackbar/showError', {
          content: error
        }))
    }
  }
}
</script>

<style></style>
